// src/utils/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged, getAuth, signOut } from 'firebase/auth';
import PropTypes from 'prop-types';
import app, { getDataOnRealtimeDb, updateDataOnRealtimeDb, saveDataOnRealtimeDb } from './firebase'; // Import your firebase config file

const auth = getAuth(app);
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [subscription, setSubscription] = useState(null);

  const handleProfileData = async (user) => {

    if (!user || !currentUser) {
      await logOut();
    }

    try {
      const data = await getDataOnRealtimeDb(`users/${user.uid}/profile`);
      const initialUserData = Object.keys(data).reduce((acc, key) => ({
        id: key,
        name: data[key].name,
        email: data[key].email,
        address: data[key].address || "",
        phoneNumber: data[key].phoneNumber || "",
      }), {});
      return initialUserData;
    } catch {
      console.log("Profile Data does Not Exist");
    }
  }



  const saveProfileData = async (user) => {
    try {
      const data = {
        name: user.displayName,
        email: user.email,
        address: "",
        phoneNumber: "",
      }
      await saveDataOnRealtimeDb(`users/${user.uid}/profile`, data);
    } catch (error) {
      console.log("Error Saving Profile Data:", error);
    }
  }


  const handleSubscriptionData = async (user) => {
    
    if (!user) {
      await logOut();
    }

    try {

      const userProfile = await getDataOnRealtimeDb(`users/${user.uid}/profile`);

      if (!userProfile) {
        await saveProfileData(user);
      }

      var initialUserData = Object.keys(userProfile).reduce((acc, key) => ({
        id: key,
        name: userProfile[key].name,
        email: userProfile[key].email,
        address: userProfile[key].address,
        phoneNumber: userProfile[key].phoneNumber,
        subscriptionStatus: userProfile[key].subscriptionStatus
      }), {});

      if (!initialUserData.subscriptionStatus) {
        initialUserData.subscriptionStatus = "free";
      }
      
      await updateDataOnRealtimeDb(`users/${user.uid}/profile`, initialUserData.id, initialUserData);
      setSubscription(initialUserData.subscriptionStatus);
    } catch {
      console.log("Subscription Data does Not Exist");
    }
  }

  const loginAction = (user) => {
    user.getIdTokenResult().then((idTokenResult) => {
      const userToken = idTokenResult.token;
      const expirationTime = Date.now() + 6 * 60 * 60 * 1000; // 6 hours in milliseconds

      localStorage.setItem('tokenExpiration', expirationTime);

      setCurrentUser(user);
      setToken(userToken);
    });
  };

  const logOut = async () => {
    await signOut(auth);
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('token');
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    setCurrentUser(null);
    setSubscription(null);
  };

  const isTokenExpired = () => {
    const expirationTime = localStorage.getItem('tokenExpiration');
    if (!expirationTime) {
      return true;
    }
    return Date.now() > parseInt(expirationTime, 10);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          const userToken = idTokenResult.token;
          const expirationTime = Date.now() + 6 * 60 * 60 * 1000; // 6 hours in milliseconds

          localStorage.setItem('tokenExpiration', expirationTime);

          setCurrentUser(user);
          setToken(userToken);
          handleSubscriptionData(user);
        });
      } else {
        setCurrentUser(null);
        setSubscription(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (isTokenExpired()) {
      logOut();
    }
  }, [token]);

  return (
    <AuthContext.Provider value={{ currentUser, subscription, loginAction, logOut, handleProfileData }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => useContext(AuthContext);
