// src/routes/PrivateRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../utils/AuthContext';

const PrivateRoute = ({ element, requiredSubscription }) => {
  const { currentUser, subscription } = useAuth();

  if (!currentUser) {
    return <Navigate to="/auth/login" />;
  }

  console.log("Required Subscription:", requiredSubscription);
  console.log("User's Subscription:", subscription);

  if (requiredSubscription && subscription !== requiredSubscription) {
    return <Navigate to="/dashboard" />; // Or some other page you want to redirect to
  }

  return element;
};

PrivateRoute.propTypes = {
  element: PropTypes.element.isRequired,
  requiredSubscription: PropTypes.string,
};

export default PrivateRoute;
