// firebase.js
import { initializeApp } from "firebase/app";
import { getDatabase, ref, get, set, push, orderByChild, equalTo, remove, child, query } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getFunctions } from 'firebase/functions';
import { ExpenseCategory, IncomeSource, billCategories } from './Catogory';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const database = getDatabase(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);

export const getUserProfile = async (userId) => {
  try {
    const userRef = ref(database, `users/${userId}`);
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      throw new Error('User profile not found');
    }
  } catch (error) {
    throw new Error('Error fetching user profile: ' + error.message);
  }
};

export const saveDataOnRealtimeDb = async (path, newData) => {
  try {
    const userRef = ref(database, `${path}`);
    const newRef = await push(userRef, newData);
    return newRef.key; // Return the generated key
  } catch (error) {
    return new Error('Error saving data profile: ' + error.message);
  }
};


export const getDataOnRealtimeDb = async (path) => {
  try {
    const userRef = ref(database, `${path}`);
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      return null;
    }
  } catch (error) {
    return Error('Error saving data profile: ' + error.message);
  }
};


export const deleteDataOnRealtimeDb = async (path, dataId) => {
  try {
    const userRef = ref(database, `${path}/${dataId}`);
    await remove(userRef);
  } catch (error) {
    throw new Error('Failed to delete expense.');
  }
};


export const updateDataOnRealtimeDb = async (path, dataId, updatedData) => {
  try {
    const userRef = ref(database, `${path}/${dataId}`);
    await set(userRef, updatedData);
  } catch (error) {
    throw new Error('Failed to update expense.');
  }
};


export const populateDefaultCatogory = async (path) => {
  try {

    var dbCategories = await getDataOnRealtimeDb(path);

    if (dbCategories != null) {
      const categories = Object.keys(dbCategories).map(key => dbCategories[key].Category);
      ExpenseCategory.map(async (value) => {
        var result = await checkExistence(categories, value.Category);
        if (!result)
          await saveDataOnRealtimeDb(path, value);
      })
    }
    else {
      ExpenseCategory.map(async (value) => {
        await saveDataOnRealtimeDb(path, value);
      })
    }

    saveBillCategoriesToRealtimeDb(path)
  } catch (error) {
    throw new Error('Failed to update category.');
  }
};

export const populateDefaultSource = async (path) => {
  try {

    var dbSources = await getDataOnRealtimeDb(path);

    if (dbSources != null) {
      const sources = Object.keys(dbSources).map(key => dbSources[key].Source);
      IncomeSource.map(async (value) => {
        var result = await checkExistence(sources, value.Source);
        if (!result)
          await saveDataOnRealtimeDb(path, value);
      })
    }
    else {
      IncomeSource.map(async (value) => {
        await saveDataOnRealtimeDb(path, value);
      })
    }
  } catch (error) {
    throw new Error('Failed to update Source.');
  }
};

export const populateEmailNotification = async (path) => {
  try {
    var emailFrequency = 1;
    var notification = await getDataOnRealtimeDb(path);

    if (notification != null) {
      var notificationValue = Object.keys(notification).map(key => notification[key].notification)
      if (notificationValue == null || notificationValue == 0)
        await saveDataOnRealtimeDb(path, { notification: emailFrequency });
    }
    else {
      await saveDataOnRealtimeDb(path, { notification: emailFrequency });
    }
  } catch (error) {
    throw new Error('Failed to update EmailNotification.');
  }
};

export const populateCurrency = async (path) => {
  try {
    var data = { currency: "USD", symbol: "$" }
    var currencyData = await getDataOnRealtimeDb(path);
    if (currencyData != null) {
      // currency = Object.keys(currencyData).map(key => currencyData[key].currency);
    }
    else{
      await saveDataOnRealtimeDb(path, data);
    }
  } catch (error) {
    throw new Error('Failed to update Currency.');
  }
}


export const populateProfileData = async (path, data) => {
  try {
    await saveDataOnRealtimeDb(path, data);
  }
  catch (error) {
    console.log(error)
    throw new Error('Failed to update expense.');
  }
};

export const populateMissingData = async (user) => {
  try {
    // Fetch the existing user profile data
    const existingData = await getDataOnRealtimeDb(`users/${user.uid}/profile`);

    if (existingData != undefined || existingData != null) {

      // Default data to be used if fields are missing
      const defaultData = {
        name: user.displayName,
        email: user.email,
        phoneNumber: user.phoneNumber,
      };

      // Check if existing data is missing any required fields
      const updatedData = {
        ...defaultData,
        name: existingData.name || defaultData.name,
        email: existingData.email || defaultData.email,
        phoneNumber: existingData.phoneNumber || defaultData.phoneNumber,
      };

      // Save the updated data to Firebase
      await saveDataOnRealtimeDb(`users/${user.uid}/profile`, updatedData);
    } else {

      // Default data to be used if fields are missing
      const defaultData = {
        name: user.displayName,
        email: user.email,
        phoneNumber: user.phoneNumber,
      };

      await saveDataOnRealtimeDb(`users/${user.uid}/profile`, defaultData);
    }

    const savedData = await getDataOnRealtimeDb(`users/${user.uid}/profile`);
    return Object.keys(savedData).reduce((acc, key) => ({
      id: key,
      name: savedData[key].name,
      email: savedData[key].email,
      address: savedData[key].address || "",
      phoneNumber: savedData[key].phoneNumber || "",
    }), {});

  } catch (error) {
    console.error('Error populating missing data:', error);
    return null; // Return null or handle the error as needed
  }
};


const checkExistence = (categories, categoryName) => {
  return categories.some(category => category === categoryName);
};

// save bill catogory

// Function to save bill categories to Firebase Realtime Database
export const saveBillCategoriesToRealtimeDb = async (path) => {
  try {
    const billCategoriesRef = ref(database, path); // Reference to the specified path
    
    // Save bill categories data
    const newRef = await push(billCategoriesRef, billCategories); 
    console.log('Bill categories saved with key:', newRef.key); // Log the generated key
    
    return newRef.key; // Return the generated key
  } catch (error) {
    console.error('Error saving bill categories:', error.message); // Handle errors
    throw new Error('Error saving bill categories: ' + error.message);
  }
};

export const populateDefaultBillCatogory = async (path) => {
  try {
    var dbCategories = await getDataOnRealtimeDb(path);
    if (dbCategories != null) {
      return
    }
    else {
      await saveBillCategoriesToRealtimeDb(path);
    }
  } catch (error) {
    throw new Error('Failed to update category.');
  }
};

export default app;